<template>
  <div class="wrapper">
    <div class="item"  @click="lookDetails('rencai')">
      <div class="img_con">
        <img src="../assets/image/solution/solution_rencai.png" alt="">
        <img class="fade_img" src="../assets/image/solution/solution_rencai2.png" alt="">
      </div>
      <label>政府人才公寓</label>
      <p>提供1个申报平台+1个物业服务平台+1个人才服务端+N个第三方开放业务场景，服务人才从“申请、入住、租后、退租”的全周期数字化管理。</p>
      <div class="btn_detail">了解详情</div>
    </div>
    <div class="item" @click="lookDetails('dichan')">
      <div class="img_con">
        <img src="../assets/image/solution/solution_dichan.png" alt="">
        <img class="fade_img" src="../assets/image/solution/solution_dichan2.png" alt="">
      </div>
      <label>保障性租赁住房</label>
      <p>以"政府主导、企业实施、市场运作、智慧管理"的"四合一"运营模式,到开发、运营、管理三位一体的经营体系，全面助力保租房企业数字化转型。</p>
      <div class="btn_detail">了解详情</div>
    </div>
    <div class="item" @click="lookDetails('shangye')">
      <div class="img_con">
        <img src="../assets/image/solution/solution_shangye.png" alt="">
        <img class="fade_img" src="../assets/image/solution/solution_shangye2.png" alt="">
      </div>
      <label>地产品牌公寓</label>
      <p>资管全流程数字化，围绕招、租、管、退四个阶段，实现公寓资产核心业务管理闭环。有效解决公寓运营痛点，提升资产运营管理效率与综合竞争力。</p>
      <div class="btn_detail">了解详情</div>
    </div>
    <div class="item" @click="lookDetails('xitong')">
      <div class="img_con">
        <img src="../assets/image/solution/solution_xitong.png" alt="" width="72">
        <img class="fade_img" src="../assets/image/solution/solution_xitong2.png" alt="" width="72">
      </div>
      <label>企业园区宿舍</label>
      <p>打造信息化宿舍生态。一键申请、智能派房，满足多场景费用补贴及费用自动核算、智能硬件设备联动，全方位展示实时宿舍数据信息。</p>
      <div class="btn_detail">了解详情</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SolutionItem",
  methods:{
    lookDetails(name){
      this.$router.push({name:'solutionDetails',params:{name:name}})
    }
  }
}
</script>

<style scoped lang="less">
.wrapper{
  width: 1200px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  box-shadow: 0px 0px 29px 0px rgba(213,217,222,.35);
  .item{
    width: 25%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding: 50px 45px;
    position: relative;
    cursor: pointer;
    transition: all .38s ease;
    .img_con{
      width: 70px;
      height: 70px;
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      img{
        position: absolute;
      }
      .fade_img{
        opacity: 0;
        transition: all .38s linear;
      }
    }
    label{
      font-size: 16px;
      font-weight: 600;
      color: #0B1D30;
      padding: 30px 0;
      cursor: pointer;
    }
    p{
      color: #42506c;
      font-size: 13px;
      text-align: left;
      height: 150px;
      margin: 0;
    }
    .btn_detail{
      width: 100%;
      height: 40px;
      line-height: 40px;
      background-color: #016FFF;
      color: #fff;
      text-align: center;
      position: absolute;
      bottom: 0;
      left: 0;
      opacity: 0;
      transition: all .38s linear;
    }
    &:hover{
      box-shadow: 0px 7px 22px 10px rgba(0, 0, 0, 0.08);
      label{
        color: #0D2A42;
      }
      .btn_detail{
        opacity: 1;
      }
      .img_con{
        .fade_img{
          opacity: 1;
        }
      }
    }
    & + .item{
      border-left: solid 1px #F4F5FA;
    }
  }
}
@media screen and (max-width: 768px){
  .wrapper{
    width: 100%;
    padding: 0 10px;
    .item{
      width: 50%;
      padding: 10px;
      border: 1px solid #EEEEEE;
    }
  }
}
</style>