<template>
  <div>
    <div class="solution_con">
      <a-row type="flex" justify="space-between">
        <a-col>
          <div class="prod">
            <div class="title">
              <img src="../assets/image/solution/Icon_1.png" alt="">
              <h3>云部署解决方案</h3>
            </div>
            <p>依托强大的云计算能力，为企业提供一站式服务，无需下载安装，无需适配不同的软硬件平台，即可轻松使用标准版系统，实现高效管理。适用于中型公寓企业。</p>
          </div>
        </a-col>
        <a-col>
          <div class="prod">
            <div class="title">
              <img src="../assets/image/solution/Icon_2.png" alt="">
              <h3>私有化解决方案</h3>
            </div>
            <p>依托企业自有平台，根据企业需求将其部署到指定的云服务器，包括管理系统、官网、官微、小程序等，形成自己的数据库，实现特色化管理。适用于中大型公寓企业。</p>
          </div>
        </a-col>
        <a-col>
          <div class="prod">
            <div class="title">
              <img src="../assets/image/solution/Icon_3.png" alt="">
              <h3>轻定制解决方案</h3>
            </div>
            <p>基于私有化解决方案增加定制服务，根据企业需求可对数据报表、支付系统、智能硬件等模块进行单独定制，功能不受云限制。适用于有清晰业务模式的品牌公寓。</p>
          </div>
        </a-col>
        <a-col>
          <div class="prod">
            <div class="title">
              <img src="../assets/image/solution/Icon_4.png" alt="">
              <h3>全定制解决方案</h3>
            </div>
            <p>组建行业资深专家组，深入企业内部全面了解业务场景，针对企业自身的业务痛点，打造专属化的解决方案，打造成企业特有的品牌。适用于大型或集团型企业。</p>
          </div>
        </a-col>
      </a-row>
    </div>
  </div>
</template>

<script>
export default {
  name: "SolutionComponent"
}
</script>

<style lang="less" scoped>
@media screen and (max-width: 768px){
  .solution_con{
    width: 94vw !important;
    overflow: hidden;
    .ant-col{
      //margin: 0 auto;
      .prod{
        width: 46vw;
        height: 380px;
      }
    }
  }
}
.solution_con{
  width: 1200px;
  margin: 0 auto;
  .prod{
    background-color: #fff;
    margin-top: 10px;
    width: 278px;
    height: 278px;
    padding: 22px;
    box-sizing: border-box;
    transition: all .3s ease-out;
    &:hover{
      transform: scale(1.16);
      box-shadow: 0 2px 4px 2px rgba(88,88,88,.1);
      h3{
        transform: translateX(-30px);
      }
      img{
        transform: translateX(30px);
      }
    }
    .title{
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: 1px solid #BFBCC4;
      padding-bottom: 10px;
      h3{
        color: #0D2A42;
        font-weight: 600;
        margin-bottom: 0;
        transition: all .3s ease-out;
      }
      img{
        transition: all .3s ease-out;
      }
    }
    p{
      color: #666;
      text-align: left;
      font-size: 16px;
      line-height: 26px;
      margin-top: 15px;
      margin-bottom: 0;
    }
  }
}
</style>